import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { FormatCurrency } from '@zento-lib/components/i18n/FormatCurrency';

import type { AppliedCoupon } from '../../types/CouponType';

import type { ICartSummaryButton } from './SummaryButton.d';
import style from './style.scss';

/**
 * Cart Summary Button
 *
 * Cart Summary Button allowing to show totals.
 **/
@Component({})
export class CartSummaryButton extends BaseComponent<ICartSummaryButton, unknown> {
  /**
   * Determines cart summary totals
   */
  @Prop({ type: Array, required: true })
  cartSummaryTotals: Array<{ code?: string; title?: string; value?: number }>;

  /**
   * Determines clear coupon
   */
  @Prop({ type: Function, default: () => undefined })
  clearCoupon?: (ev: Event) => void;

  /**
   * Determines removing close button for coupon added
   */
  @Prop({ type: Boolean, default: false })
  hideRemoveCouponButton?: boolean;

  /**
   * Get applied coupon
   */
  get appliedCoupon(): AppliedCoupon {
    return this.$store.getters['cart/getCoupon'];
  }

  render() {
    return (
      <div class={style.cartShoppingSummaryWrapper}>
        <ul class={style.cartShoppingSummary}>
          {this.cartSummaryTotals.map((segment) => {
            return (
              <li key={segment.code}>
                <span>{segment.title}</span>
                {this.appliedCoupon && segment.code === 'discount' && !this.hideRemoveCouponButton ? (
                  <span onClick={this.clearCoupon} class={style.cartClearCoupon} />
                ) : null}
                {segment.value !== null ? (
                  <span>
                    <FormatCurrency value={segment.value} />
                  </span>
                ) : null}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
