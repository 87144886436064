import type { IProductState } from 'theme/@types/vsf/stores/product';
import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import type { ICartHeader } from './Header.d';
import style from './style.scss';

/**
 * Cart Header
 *
 * Renders custom content on cart header
 **/
@Component({})
export class CartHeader extends BaseComponent<ICartHeader, unknown> {
  /**
   * Current products found in cart
   */
  @Prop({ type: Array, required: true })
  products: IProductState[];

  /**
   * A text representing cart title
   */
  @Prop({ type: Object, required: true })
  cartTitle: I18nMessage;

  /**
   * A text representing number of products found in cart (if more than one)
   */
  @Prop({ type: Object, required: true })
  cartCountProducts: I18nMessage;

  /**
   * A text representing cart product count (exactly one)
   */
  @Prop({ type: Object, required: true })
  cartCountProduct: I18nMessage;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   */
  @Prop({ type: Function, default: () => undefined, required: false })
  handler?: (ev: Event) => void;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   * In this case, it will empty the cart
   */
  @Prop({ type: Function, default: () => undefined })
  clearCart?: (ev: Event) => void;

  /**
   * An attribute used to define a string that labels the current element
   */
  @Prop({ type: Object, default: undefined })
  ariaLabel?: I18nMessage;

  /**
   * A text representing clear cart button label
   */
  @Prop({ type: Object, default: undefined })
  clearCartBtnLabel?: I18nMessage;

  /**
   * This property sets or returns whether cart header close button is visible
   */
  @Prop({ type: Boolean, required: true })
  showBtn: boolean;

  /**
   * Determines if wishlist has some custom styles
   */
  @Prop({ type: Boolean, required: false, default: false })
  customStyle?: boolean;

  render() {
    return (
      <div class={{ [style.microcartSidebarHeader]: true, [style.cartSidebarCustom]: this.customStyle }}>
        <h2 class={style.microcartSidebarTitle}>
          {this.getTranslation(this.cartTitle)}
          {this.products.length && this.products.length > 1 ? (
            <span class={style.microcartSidebarCount}>{this.getTranslation(this.cartCountProducts)}</span>
          ) : this.products.length ? (
            <span class={style.microcartSidebarCount}>{this.getTranslation(this.cartCountProduct)}</span>
          ) : null}
          {this.products.length && this.products.length > 1 ? (
            <span onClick={this.clearCart} class={style.microcartClearCart}>
              {this.getTranslation(this.clearCartBtnLabel)}
            </span>
          ) : null}
        </h2>
        {this.showBtn ? (
          <span
            onClick={this.handler}
            aria-label={this.getTranslation(this.ariaLabel)}
            data-testId='closeCart'
            class={style.microcartClose}
          />
        ) : null}
      </div>
    );
  }
}
